<template>
  <div class="headdiv">

    <div style="width: 99.7%">
      <div id="alldiv2">
        <div style="width: 15%;border-right:3px solid #F00;height: 50px">
          劳工姓名
        </div>
        <div style="width: 15%;border-right:3px solid #F00">
          劳工联系方式
        </div>
        <div style="width: 35%;border-right:3px solid #F00">
          进洞时间
        </div>
        <div style="width: 35%;">
          出洞时间
        </div>
      </div>
    </div>

    <div>
      <vue3-seamless-scroll :list="list" class="scroll">
        <div class="item" v-for="(item, index) in list" :key="index">
          <div id="alldiv">
            <div style="width: 15%;border-right:3px solid #F00;height: 50px">
              {{ item.workername }}
            </div>
            <div style="width: 15%;border-right:3px solid #F00">
              {{ item.workertel }}
            </div>
            <div style="width: 35%;border-right:3px solid #F00">
              {{ item.inopertime }}
            </div>
            <div style="width: 35%;">
              {{ item.outopertime }}
            </div>
          </div>
        </div>
      </vue3-seamless-scroll>
    </div>
  </div>
</template>

<script>
import {Vue3SeamlessScroll} from "vue3-seamless-scroll";
import {useRouter} from 'vue-router'

export default {
  name: "bigshow",
  data() {
    return {
      opername: '',
    }
  },
  components: {
    Vue3SeamlessScroll
  },
  setup() {
    let route = useRouter();
    let list = JSON.parse(sessionStorage.getItem(route.currentRoute.value.params.opername));
    return {list}
  },
  methods: {}
}
</script>

<style scoped>
.scroll {
  height: 1024px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto 0px auto;
}

.scroll .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 0;
}

#alldiv {
  width: 100%;
  display: flex;
  border-top: 3px solid #F00;
  border-left: 3px solid #F00;
  border-right: 3px solid #F00;
  border-bottom: 3px solid #F00;
  color: red;
  text-align: center;
  line-height: 50px;
  font-size: 25px;
  font-weight: bolder;
}

#alldiv2 {
  width: 100%;
  display: flex;
  border-top: 3px solid #F00;
  border-left: 3px solid #F00;
  border-right: 3px solid #F00;
  border-bottom: 3px solid #F00;
  color: red;
  text-align: center;
  line-height: 50px;
  font-size: 25px;
  font-weight: bolder;
}

.headdiv {
  background-color: black;
  height: 30%;
  width: 100%;
  overflow: hidden
}
</style>